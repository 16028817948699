<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ประเภทยาและเวชภัณฑ์</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="3">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <!-- <span class="label-search-left">หมวดหมู่</span> -->
              <v-select
                :items="itemsProductTypeFilter"
                v-model="select_filter_type"
                item-text="name"
                item-value="name"
                label="เลืือกหมวดหมู่"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <!-- <span class="label-search-left">หมวดหมู่</span> -->
              <v-select
                :items="itemsProductGroupFilter"
                v-model="select_filter_group"
                item-text="name"
                item-value="name"
                label="เลืือกกลุ่ม"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-end justify-end">
            <v-btn
              :loading="loader"
              :disabled="loader"
              color="blue-grey"
              class="my-3 white--text"
              @click="addItem"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              เพิ่มประเภทยาและเวชภัณฑ์
            </v-btn>
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">{{item.product_category}}</td>
          <td class="text-center">{{item.product_group_name}}</td>

          <td>
            <div v-if="item.product_type === 'ยา'" class="d-flex flex-row justify-center">
              <v-icon class="mr-2">mdi-pill</v-icon>
              <span>ยา</span>
            </div>
            <div v-else class="d-flex flex-row justify-center">
              <v-icon class="mr-2">mdi-needle</v-icon>
              <span>เวชภัณฑ์</span>
            </div>
          </td>

          <td class="d-flex justify-center">
            <v-switch
              v-model="item.product_status_bool"
              inset
              dense
            ></v-switch>
          </td>

          <td class="text-center">
            <v-btn
              icon
              @click="viewItem(item)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="editItem(item)"
            >
              <v-icon>mdi-file-edit-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="deleteItem(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title>{{boolAddItems ? 'เพิ่มข้อมูลประเภทยาและเวชภัณฑ์' : boolViewItems ? 'ข้อมูลประเภทยาและเวชภัณฑ์' : boolEditItems ? 'แก้ไขข้อมูลประเภทยาและเวชภัณฑ์' : 'ลบข้อมูลประเภทยาและเวชภัณฑ์'}}</v-card-title>
        <v-card-text v-if="boolAddItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อประเภท:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_category"
                label="ชื่อประเภท"
                placeholder="ระบุชื่อประเภท"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อกลุ่ม:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsProductGroupCreate"
                v-model="dataDialog.product_group_name"
                item-text="name"
                item-value="value"
                label="เลือกชื่อกลุ่ม"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsProductTypeCreate"
                v-model="dataDialog.product_type"
                item-text="name"
                item-value="value"
                label="เลือกหมวดหมู่"
                disabled
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolViewItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อประเภท:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_category}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อกลุ่ม:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_group_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <div v-if="dataDialog.product_type === 'ยา'" class="d-flex flex-row">
                <v-icon class="mr-2">mdi-pill</v-icon>
                <span>ยา</span>
              </div>
              <div v-else class="d-flex flex-row">
                <v-icon class="mr-2">mdi-needle</v-icon>
                <span>เวชภัณฑ์</span>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
                disabled
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolEditItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อประเภท:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_category"
                label="ชื่อประเภท"
                placeholder="ระบุชื่อประเภท"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อกลุ่ม:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsProductGroupCreate"
                v-model="dataDialog.product_group_value"
                item-text="name"
                item-value="value"
                label="เลือกชื่อกลุ่ม"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsProductTypeCreate"
                v-model="dataDialog.product_type_value"
                item-text="name"
                item-value="value"
                label="เลือกหมวดหมู่"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <span>{{`ต้องการลบประเภท ${dataDialog.product_category || ''} นี้ใช่หรือไม่`}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!boolViewItems" color="error" text @click="closeDialog">
            <v-icon v-if="!boolDelItems" class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            :color="boolViewItems ? 'blue darken-1' : 'success'"
            text
            @click="submitDialog"
            :disabled="!cansave"
          >
            <v-icon v-if="!boolViewItems && !boolDelItems" class="mr-3">mdi-content-save</v-icon>
            {{boolViewItems ? 'ปิด' : boolAddItems ? 'เพิ่ม' : boolEditItems ? 'บันทึก' : 'ยืนยัน'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      select_filter_type: '',
      select_filter_group: '',
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      data: [],
      itemsProductTypeFilter: [],
      itemsProductTypeCreate: [],
      itemsProductGroupFilter: [],
      itemsProductGroupCreate: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'ชื่อประเภท',
          align: 'center',
          sortable: false,
          value: 'product_category'
        }, {
          text: 'ชื่อกลุ่ม',
          align: 'center',
          sortable: false,
          value: 'product_group_name'
        }, {
          text: 'หมวดหมู่',
          align: 'center',
          value: 'product_type',
          sortable: false
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'product_status',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    select_filter_type () {
      const self = this
      if (self.select_filter_type === 'ทั้งหมด') {
        if (self.select_filter_group) {
          self.filter_search = self.select_filter_group
        } else self.filter_search = ''
      } else {
        // if (self.select_filter_group) self.filter_search = `${self.select_filter_type}, ${self.select_filter_group}`
        // else self.filter_search = self.select_filter_type
        self.filter_search = self.select_filter_type
      }
    },
    select_filter_group () {
      const self = this
      if (self.select_filter_group === 'ทั้งหมด') {
        if (self.select_filter_type) {
          self.filter_search = self.select_filter_type
        } else self.filter_search = ''
      } else {
        self.filter_search = self.select_filter_group
      }
    },
    'dataDialog.product_group_name' () {
      const self = this
      if (Object.keys(self.itemsProductGroupFilter.find(x => x._id === self.dataDialog.product_group_name)).length) {
        self.dataDialog.product_type = (self.itemsProductGroupFilter.find(x => x._id === self.dataDialog.product_group_name)).type_value
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.fetchCategory()
    },
    async fetchCategory () {
      const self = this
      var tempFilter = []
      var tempCreate = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/category', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.category,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.push({
            _id: '0',
            name: 'ทั้งหมด',
            value: ''
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
          tempCreate = tempFilter.slice(1)
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsProductTypeCreate = tempCreate
        self.itemsProductTypeFilter = tempFilter
        self.fetchGroup()
      }
    },
    async fetchGroup () {
      const self = this
      var tempFilter = []
      var tempCreate = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/group', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.group,
              value: el._id,
              created_at: el.create_at,
              type: el.category.category,
              type_value: el.category._id
            })
          })
          tempFilter.push({
            _id: '0',
            name: 'ทั้งหมด',
            value: ''
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
          tempCreate = tempFilter.slice(1)
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsProductGroupCreate = tempCreate
        self.itemsProductGroupFilter = tempFilter
        self.fetchData()
      }
    },
    async fetchData () {
      const self = this
      self.loaddata = true
      var temp = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/type', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            self.itemsProductTypeCreate.map(e => {
              self.itemsProductGroupCreate.map(ele => {
                if (e._id === el.category._id && ele._id === el.group._id) {
                  temp.push({
                    _id: el._id,
                    product_category: el.type,
                    product_group_name: ele.name,
                    product_group_value: ele.value,
                    product_type: e.name,
                    product_type_value: e.value,
                    product_status: el.active ? 'active' : 'inactive',
                    product_status_bool: el.active,
                    created_at: el.create_at
                  })
                }
              })
            })
          })
          temp.sort((a, b) => {
            if (a.product_category < b.product_category) { return -1 }
            if (a.product_category > b.product_category) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.data = temp
        self.loaddata = false
      }
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    addItem () {
      const self = this
      self.boolAddItems = true
      self.showDialog = true
    },
    viewItem (item) {
      const self = this
      self.dataDialog = item
      self.boolViewItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      self.dataDialog = item
      self.boolEditItems = true
      self.showDialog = true
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolAddItems = false
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
      self.initData()
      self.cansave = true
    },
    async submitDialog () {
      const self = this
      if (self.boolAddItems) {
        if (self.dataDialog.product_category && self.dataDialog.product_group_name && self.dataDialog.product_type) {
          const obj = {
            type: self.dataDialog.product_category,
            group: self.dataDialog.product_group_name,
            category: self.dataDialog.product_type
          }
          self.cansave = false
          try {
            const res = await self.axios.post(process.env.VUE_APP_API + '/product/type', obj, {
              headers: {
                Authorization: localStorage.getItem('Token') || self.$store.state.token
              }
            })
            if (res.status === 200) {
              self.closeDialog()
              swal('สำเร็จ', 'เพิ่มข้อมูบประเภทยาและเวชภัณฑ์สำเร็จ', 'success', {
                button: false,
                timer: 2000
              })
            } else {
              swal('ผิดพลาด', 'เพิ่มข้อมูลประเภทยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
                button: false,
                timer: 3000
              })
            }
          } catch (error) {
            swal('ผิดพลาด', 'เพิ่มข้อมูลประเภทยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          }
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลประเภทยาและเวชภัณฑ์ให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolEditItems) {
        if (self.dataDialog.product_category && self.dataDialog.product_group_name && self.dataDialog.product_type) {
          try {
            const obj = {
              type: self.dataDialog.product_category,
              group: self.dataDialog.product_group_value,
              category: self.dataDialog.product_type_value
            }
            self.cansave = false
            const res = await self.axios.put(`${process.env.VUE_APP_API}/product/type/${self.dataDialog._id}`, obj, {
              headers: {
                Authorization: localStorage.getItem('Token') || self.$store.state.token
              }
            })
            if (res.status === 200) {
              swal('สำเร็จ', 'แก้ไขข้อมูลประเภทยาและเวชภัณฑ์สำเร็จ', 'success', {
                button: false,
                timer: 2000
              })
            } else {
              swal('ผิดพลาด', 'แก้ไขข้อมูลประเภทยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
                button: false,
                timer: 3000
              })
            }
          } catch (error) {
            swal('ผิดพลาด', 'แก้ไขข้อมูลประเภทยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          } finally {
            self.cansave = true
            self.closeDialog()
          }
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลประเภทยาและเวชภัณฑ์ให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolDelItems) {
        try {
          self.cansave = false
          const res = await self.axios.delete(`${process.env.VUE_APP_API}/product/type/${self.dataDialog._id}`, {
            headers: {
              Authorization: localStorage.getItem('Token') || self.$store.state.token
            }
          })
          if (res.status === 200) {
            swal('สำเร็จ', 'ลบข้อมูลประเภทยาและเวชภัณฑ์สำเร็จ', 'success', {
              button: false,
              timer: 2000
            })
          } else {
            swal('ผิดพลาด', 'ลบข้อมูลประเภทยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          }
        } catch (error) {
          swal('ผิดพลาด', 'ลบข้อมูลประเภทยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
            button: false,
            timer: 3000
          })
        } finally {
          self.cansave = true
          self.closeDialog()
        }
      } else {
        self.closeDialog()
      }
    }
  }
}
</script>
